//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventForm from '@/components/Event/EventForm'
import LeftSidebar from '@/components/Sidebars/LeftSidebar.vue'
export default {
    name: 'EventSettings',
    components: {
        EventForm,
        LeftSidebar
    },
    computed: {
      event() {
        return this.$store.getters['event/getEvent']
      }
    },
    data() {
        return {
          isSaving: false
        }
    },
    methods: {
      fetchEvent() {
          this.$store.dispatch('event/fetchEvent', this.$route.params.id)
          .then((response) =>{
              if(response.message == 'success'){
                  this.isAdmin = response.isAdmin
                  this.isMember = response.isMember
                  this.isOwner = response.isOwner
                  this.membersCount = response.membersCount
              }
          })  
      },
        onSubmit(payload) {
            this.isSaving = true;
            payload.id = this.$route.params.id;
            this.$store.dispatch('event/updateEvent', payload).then((response) => {
                this.isSaving = false;
                if (response.message == 'success') {
                  this.$router.push({
                    name: 'Event', params: {
                      id: this.$route.params.id
                    }
                  });
                  this.$q.notify({
                    message: 'The event has been successfully updated.',
                    color: 'positive',
                    icon: 'check'
                  });
                } else {
                    this.$q.notify({
                        message: 'An error has occurred while trying to create event.',
                        color: 'negative',
                        icon: 'error'
                    });
                }
            })
        }
    }
}
