//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import CourseList from '@/components/CourseList'
export default {
    name: 'EventForm',
    created() {
        this.loadEventPars(this.event);
    },
    components: {
        CourseList
    },
    props: {
        event: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    description: '',
                    privacy: 'Public',
                    date_start: '',
                    date_end: null,
                    time_start: '08:00',
                    time_end: '17:00',
                    course_id: 1,
                    venue: '',
                    system_format: '',
                    system_scoring: '',
                    green_fee: '0.00',
                    caddie_fee: '0.00',
                    hole_in_one_prize: '',
                    first_prize: '',
                    second_prize: '',
                    third_prize: '',
                    venueType: 'venue',
                    isMultipleDays: false     
                }           
            }
        }
    },
    data() {
        return {
            name: (this.event.name) ? this.event.name : '',
            description: (this.event.description) ? this.event.description : '',
            privacy: (this.event.privacy) ? this.event.privacy : 'Public',
            date_start: (this.event.date_start) ? this.event.date_start : moment(moment().add(10, 'days').calendar()).format('YYYY/MM/DD'),
            date_end: (this.event.date_end) ? this.event.date_end  : null,
            time_start: (this.event.time_start) ? this.event.time_start : '08:00',
            time_end: (this.event.time_end) ? this.event.time_end : '17:00',
            course_id: (this.event.course_id) ? this.event.course_id : 1,
            venue: (this.event.venue) ? this.event.venue : '',
            system_format: (this.event.system_format) ? this.event.system_format : '',
            system_scoring: (this.event.system_scoring) ? this.event.system_scoring : 'Stroke Play',
            green_fee: (this.event.green_fee) ? this.event.green_fee : '0.00',
            caddie_fee: (this.event.caddie_fee) ? this.event.caddie_fee : '0.00',
            hole_in_one_prize: (this.event.hole_in_one_prize) ? this.event.hole_in_one_prize : '',
            first_prize: (this.event.first_prize) ? this.event.first_prize : '',
            second_prize: (this.event.second_prize) ? this.event.second_prize : '',
            third_prize: (this.event.third_prize) ? this.event.third_prize : '',
            venueType: (this.event.course_id) ? 'course' : 'venue',
            isMultipleDays: (this.event.date_end) ? true : false,
            step: 1,
            option_scoring_type: ['Stroke Play'],
            par_counts: [
                {id:'1', count: 3},
                {id:'2', count: 3},
                {id:'3', count: 3},
                {id:'4', count: 3},
                {id:'5', count: 3},
                {id:'6', count: 3},
                {id:'7', count: 3},
                {id:'8', count: 3},
                {id:'9', count: 3},
                {id:'10',count: 3},
                {id:'11',count: 3},
                {id:'12',count: 3},
                {id:'13',count: 3},
                {id:'14',count: 3},
                {id:'15',count: 3},
                {id:'16',count: 3},
                {id:'17',count: 3},
                {id:'18',count: 3},
            ],
            game_rounds: (this.event.rounds) ? this.event.rounds : 1,
            par_count_options: ['3', '4', '5', '6', '7']

        }
    },
    methods: {
        submit() {
            if (!this.isMultipleDays) {
                this.date_end = null;
            }

            if (this.venueType == 'course') {
                this.venue = null;
            }

            this.$emit('submit', {
                name: this.name,
                description: this.description,
                privacy: this.privacy,
                date_start: this.date_start,
                date_end: this.date_end,
                time_start: this.time_start,
                time_end: this.time_end,
                course_id: this.course_id,
                venue: this.venue,
                system_format: this.system_format,
                system_scoring: this.system_scoring,
                green_fee: this.green_fee,
                caddie_fee: this.caddie_fee,
                hole_in_one_prize: this.hole_in_one_prize,
                first_prize: this.first_prize,
                second_prize: this.second_prize,
                third_prize: this.third_prize,
                rounds: this.game_rounds,
                hole_1: this.par_counts[0]['count'] == "" ? '3' : this.par_counts[0]['count'],
                hole_2: this.par_counts[1]['count'] == "" ? '3' : this.par_counts[1]['count'],
                hole_3: this.par_counts[2]['count'] == "" ? '3' : this.par_counts[2]['count'],
                hole_4: this.par_counts[3]['count'] == "" ? '3' : this.par_counts[3]['count'],
                hole_5: this.par_counts[4]['count'] == "" ? '3' : this.par_counts[4]['count'],
                hole_6: this.par_counts[5]['count'] == "" ? '3' : this.par_counts[5]['count'],
                hole_7: this.par_counts[6]['count'] == "" ? '3' : this.par_counts[6]['count'],
                hole_8: this.par_counts[7]['count'] == "" ? '3' : this.par_counts[7]['count'],
                hole_9: this.par_counts[8]['count'] == "" ? '3' : this.par_counts[8]['count'],
                hole_10:this.par_counts[9]['count'] == "" ? '3' : this.par_counts[9]['count'],
                hole_11: this.par_counts[10]['count'] == "" ? '3' : this.par_counts[10]['count'],
                hole_12: this.par_counts[11]['count'] == "" ? '3' : this.par_counts[11]['count'],
                hole_13: this.par_counts[12]['count'] == "" ? '3' : this.par_counts[12]['count'],
                hole_14: this.par_counts[13]['count'] == "" ? '3' : this.par_counts[13]['count'],
                hole_15: this.par_counts[14]['count'] == "" ? '3' : this.par_counts[14]['count'],
                hole_16: this.par_counts[15]['count'] == "" ? '3' : this.par_counts[15]['count'],
                hole_17: this.par_counts[16]['count'] == "" ? '3' : this.par_counts[16]['count'],
                hole_18: this.par_counts[17]['count'] == "" ? '3' : this.par_counts[17]['count'],
            });
        },
        reset() {
            this.name = (this.event.name) ? this.event.name : '',
            this.description = (this.event.description) ? this.event.description : '',
            this.privacy = (this.event.privacy) ? this.event.privacy : '',
            this.date_start = (this.event.date_start) ? this.event.date_start : moment(moment().add(10, 'days').calendar()).format('YYYY/MM/DD'),
            this.date_end = (this.event.date_end) ? this.event.date_end : moment(moment().add(20, 'days').calendar()).format('YYYY/MM/DD'),
            this.time_start = (this.event.time_start) ? this.event.time_start : '08:00',
            this.time_end = (this.event.time_end) ? this.event.time_end : '17:00',
            this.course_id = (this.event.course_id) ? this.event.course_id : 1,
            this.venue = (this.event.venue) ? this.event.venue : null,
            this.system_format = (this.event.system_format) ? this.event.system_format : '',
            this.system_scoring = (this.event.system_scoring) ? this.event.system_scoring : '',
            this.green_fee = (this.event.green_fee) ? this.event.green_fee : '0.00',
            this.caddie_fee = (this.event.caddie_fee) ? this.event.caddie_fee : '0.00',
            this.hole_in_one_prize = (this.event.hole_in_one_prize) ? this.event.hole_in_one_prize : '',
            this.first_prize = (this.event.first_prize) ? this.event.first_prize : '',
            this.second_prize = (this.event.second_prize) ? this.event.second_prize : '',
            this.third_prize = (this.event.third_prize) ? this.event.third_prize : '',
            this.venueType = 'venue',
            this.isMultipleDays = false
        },
        setCourse (course_id) {
            this.course_id = course_id;
            this.venueType = 'course';
        },
        loadEventPars(event) {
            if (!event) {
                return;
            }
            this.par_counts[0]['count'] = event.hole_1;
            this.par_counts[1]['count'] = event.hole_2;
            this.par_counts[2]['count'] = event.hole_3;
            this.par_counts[3]['count'] = event.hole_4;
            this.par_counts[4]['count'] = event.hole_5;
            this.par_counts[5]['count'] = event.hole_6;
            this.par_counts[6]['count'] = event.hole_7;
            this.par_counts[7]['count'] = event.hole_8;
            this.par_counts[8]['count'] = event.hole_9;
            this.par_counts[9]['count'] = event.hole_10;
            this.par_counts[10]['count'] = event.hole_11;
            this.par_counts[11]['count'] = event.hole_12;
            this.par_counts[12]['count'] = event.hole_13;
            this.par_counts[13]['count'] = event.hole_14;
            this.par_counts[14]['count'] = event.hole_15;
            this.par_counts[15]['count'] = event.hole_16;
            this.par_counts[16]['count'] = event.hole_17;
            this.par_counts[17]['count'] = event.hole_18;
        }
    }
}
