//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
export default {
    name: "LeftSidebar",
    data(){
      return{
        emailaddress: this.$store.state.user.email_address
      }
    },
    computed: {
      coverPhoto(){
        return this.$store.getters['user/getCoverPhoto']
      },
      fullname () {
        return this.$store.getters['user/getFullName']
      },
      friendsCount () {
        return this.$store.state.user.user_friends.length
      },
      profile_photo (){
        return this.$store.state.user.profile_photo
      }
    },
    methods: {
        ...mapActions({
            openImgViewer: "OpenImgViewer"
        }),
       showProfileImg (img){
           this.openImgViewer(img)
       }
    },
}
